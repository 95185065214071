import React from 'react';
import ReactHlsPlayer from 'react-hls-player';

const HLSPage = () => (
  <ReactHlsPlayer 
    src="http://petesbirdcam.hopto.org:8080/birdcam.m3u8"
    controls={true}
    width="100%"
    height="auto"
    muted={true}
    onVolumeChange={(e) => {
      e.preventDefault()
      e.target.muted = true
    }}
  />
);

export default HLSPage;
